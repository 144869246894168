@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./assets/fonts/Ubuntu-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./assets/fonts/Ubuntu-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./assets/fonts/Ubuntu-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./assets/fonts/Ubuntu-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./assets/fonts/Ubuntu-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./assets/fonts/Ubuntu-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./assets/fonts/Ubuntu-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./assets/fonts/Ubuntu-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

* {
  font-family: 'Ubuntu';
}

html {
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  padding-top: 80px;
}

main {
  display: flex;
  flex-direction: column;
}

p {
  font-size: 18px;
  line-height: 30px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
